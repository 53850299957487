import React, { useContext, useState } from "react";
import { BodyImg, TittlePage, SubTittle, StyledCarousel, VideoContainer, CardFilms } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import MenuHome from "../../../Components/Menu";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'primereact/carousel';
import floresRaras from "../../../assests/imgs/Filme - Flores Raras.jpg";
import imagineYou from "../../../assests/imgs/Filme - Imagine eu e você.jpg";
import bottoms from "../../../assests/imgs/Filme - Bottoms.jpg";
import loveLies from "../../../assests/imgs/Love-Liers-Bleeding.jpg";
import theWorld from "../../../assests/imgs/Filme - The world to come.jpg";
import rafiki from "../../../assests/imgs/Filme - Rafiki.png";
import criada from "../../../assests/imgs/Filme - A criada.jpg";
import trailertest from "../../../assests/videos/happiestSeasonTrailerPTBR.mp4";

const Films = () => {
    const { userInfos } = useContext(GlobalStateContext);
    const navigate = useNavigate();

    const mockProducts = [
        { id: 1, name: "Flores Raras", image: floresRaras, trailer: trailertest },
        { id: 2, name: "Imagine Me and You", image: imagineYou, trailer: "" },
        { id: 3, name: "Bottoms", image: bottoms, trailer: "" },
        { id: 4, name: "Love Lies Bleeding", image: loveLies, trailer: "" },
        { id: 5, name: "The World To Come", image: theWorld, trailer: "" },
        { id: 6, name: "Rafiki", image: rafiki, trailer: "" },
        { id: 7, name: "A Criada", image: criada, trailer: "" }
    ];

    const [products, setProducts] = useState(mockProducts);

    const responsiveOptions = [
        { breakpoint: '1400px', numVisible: 2, numScroll: 1 },
        { breakpoint: '1199px', numVisible: 3, numScroll: 1 },
        { breakpoint: '767px', numVisible: 2, numScroll: 1 },
        { breakpoint: '575px', numVisible: 1, numScroll: 1 }
    ];

    const handleMouseOver = (trailerUrl) => {
        const videoElement = document.querySelector(`video[data-trailer="${trailerUrl}"]`);
        const titleElement = document.querySelector(`p[data-trailer-title="${trailerUrl}"]`);
        if (videoElement) {
            videoElement.style.display = 'block';
            videoElement.play();
        }
        if (titleElement) {
            titleElement.style.display = 'block';
        }
    };

    const handleMouseOut = (trailerUrl) => {
        const videoElement = document.querySelector(`video[data-trailer="${trailerUrl}"]`);
        const titleElement = document.querySelector(`p[data-trailer-title="${trailerUrl}"]`);
        if (videoElement) {
            videoElement.pause();
            videoElement.style.display = 'none';
        }
        if (titleElement) {
            titleElement.style.display = 'none';
        }
    };

    const productTemplate = (product) => {
        return (
            <VideoContainer
                className="border-1 surface-border border-round m-2 text-center py-5 px-3"
                onMouseOver={() => handleMouseOver(product.trailer)}
                onMouseOut={() => handleMouseOut(product.trailer)}
            >
                <CardFilms src={product.image} alt={product.name} style={{
                    width: "96%",
                    height: "214px",
                    borderRadius: "20px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.6)",
                    cursor: "pointer"
                }} className="w-6 shadow-2" />
                {product.trailer && (
                    <video data-trailer={product.trailer} style={{ display: 'none', height: 214 }}>
                        <source src={product.trailer} type="video/mp4" />
                        Seu navegador não suporta vídeos.
                    </video>
                )}
                {/* <p data-trailer-title={product.trailer} className="film-title" style={{ display: 'none', marginTop: '10px', color: 'white' }}>
                    {product.name}
                </p> */}
            </VideoContainer>
        );
    };

    return (
        <BodyImg>
            <MenuHome />
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "100px", marginTop: "60px" }}>
                <TittlePage>FILMES</TittlePage>
                <SubTittle>Qual é o clima de hoje? Tem para rir, para chorar e tem clichê, para mim e para você.</SubTittle>
            </div>
            <StyledCarousel>
                <div className="card">
                    <Carousel
                        value={products}
                        numVisible={5}
                        numScroll={1}
                        responsiveOptions={responsiveOptions}
                        className="custom-carousel"
                        circular
                        itemTemplate={productTemplate}
                        showIndicators={false}
                    />
                </div>
            </StyledCarousel>
        </BodyImg>
    );
};

export default Films;
