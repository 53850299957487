import React from "react";
import styled from "styled-components";

import fundoinfo from "../../../assests/imgs/fundoinfo.png";

export const BodyImg = styled.div`
  height: 100vh;
  width: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  display: flex;
  flex-direction: column;

  position: relative; // Necessário para posicionar corretamente o pseudo-elemento

  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1.5) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 0.7) 100%
    ); // Cria o esfumaçado preto na parte superior e inferior
  }
`;

export const Body = styled.div`
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  display: flex;
  flex-direction: column;

  position: relative;
`;
export const DivideBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out; // Adiciona uma transição suave
  cursor: pointer;

  &:hover {
    transform: translateY(-5px); // Move o Box para cima ao passar o mouse
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); // Adiciona uma sombra para o efeito de flutuação
  }
`;
export const BoxNoHover = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TittlePage = styled.text`
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 29px;
  letter-spacing: 0.2em;
  color: white;
  margin-top: 200px;
`;
export const TittlePageName = styled.text`
  font-style: normal;
  font-weight: 100;
  font-size: 30px;
  line-height: 29px;
  letter-spacing: 0.2em;
  color: white;
  margin-top: 20px;
`;

export const ButtonDisplay = styled.div`
  display: flex;
  margin-right: 69px;
  cursor: pointer;
  gap: 19px;
`;

export const TextBody = styled.text`
  display: flex;
  color: #ffffff;
  max-width: 958px;
  font-size: 25px;
  font-weight: 500;
  line-height: 24.3px;
  text-align: left;
`;

export const DivLogo = styled.div``;
export const DivInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 30px;
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
`;

export const DivSinopse = styled.div`
  display: flex;
  color: #ffffff;
  max-width: 958px;
  font-size: 25px;
  letter-spacing: 0.3px;
  font-weight: 300;
  line-height: 24.3px;
  margin-top: 35px;
`;
export const ButtonImg = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    :hover {
      opacity: 0.5;
    }
  }
`;

// Contêiner para envolver as imagens
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

// Imagem de fundo com o fade overlay
const FadedImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const FadeOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  pointer-events: none;
`;

export const OverlayImage3 = styled.img`
  position: absolute;

  bottom: 0px;
  max-width: 30%;
  background-color: transparent;

  z-index: 1;
  width: 332.21px;
  height: 546.02px;
  bottom: 0px;
`;

// Componente principal
const FadedImageComponent = () => (
  <ImageWrapper>
    <FadedImage src={fundoinfo} alt="Fundo Estático" />
    <FadeOverlay />
  </ImageWrapper>
);

export default FadedImageComponent;
