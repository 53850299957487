import React, { useContext, useState } from "react";
import {BodyImg, TittlePage, SubTittle, StyledCarousel, CardFilms } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import MenuHome from "../../../Components/Menu";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'primereact/carousel';
import exemploimg from "../../../assests/imgs/Gloria-Pires.png"
import warriornun from "../../../assests/imgs/Warrior-Nun.jpg"
import glee from "../../../assests/imgs/Glee.png"
import orange from "../../../assests/imgs/Série - Orange is the nwe black.jpg"
import theL from "../../../assests/imgs/The-L-World.jpg"
import wynonna from "../../../assests/imgs/Série - Wynonna Earp.jpg"
import imNot from "../../../assests/imgs/Série - I'm not okay with this.png"
import everything from "../../../assests/imgs/Série - Everything Sucks.png"
import addIcon from '../../../assests/icons/iconfavorit.png'

const Series = () => {
    const { userInfos } = useContext(GlobalStateContext);

    const navigate = useNavigate();

    const mockProducts = [
        {
          id: 1,
          name: "Everything Sucks",
          image: everything
        },
        {
          id: 2,
          name: "Glee",
          image: glee
        },
        {
          id: 3,
          name: "Orange Is The New Black",
          image: orange
        },
        {
            id: 1,
            name: "The L Word",
            image: theL
          },
          {
            id: 2,
            name: "Wynonna",
            image: wynonna
          },
          {
            id: 3,
            name: "I'm Not Okay With This",
            image: imNot
          },
  
      ];
      

    const [products, setProducts] = useState(mockProducts);
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    
    

    const productTemplate = (product) => {
        return (
          <div >
            <div>
              <CardFilms
                style={{
                  width: "96%",
                  height: "214px",
                  borderRadius: "20px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.6)",
                  cursor:"pointer"
                }}
                src={`${product.image}`}
                alt={product.name}
              />
              <div style={{ position: "relative", left: '80%', bottom: 70, height: 50, width: 50, backgroundColor: "transparent", cursor: 'pointer' }} >
                <img style={{height: 40, width: 40}} src={addIcon}></img>
              </div>
            </div>
          </div>
        );
      };

    return (
        <>
            <BodyImg>
                <MenuHome />
                <div style={{ display: "flex",  flexDirection: "column", marginLeft: "100px", marginTop: "60px"}}>
                    <TittlePage>
                    SÉRIES
                    </TittlePage>
                    <SubTittle>Se está disponível, por que não assistir tudo de uma vez mesmo? Sem pressão!</SubTittle>
                </div>
                <StyledCarousel>
                <div className="card">
                    <Carousel value={products} numVisible={5} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                     itemTemplate={productTemplate} showIndicators={false}  />
                </div>
                </StyledCarousel>
            </BodyImg>
        </>
    )
}

export default Series;