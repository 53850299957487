import { Carousel } from "primereact/carousel";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import fisrImg from "../../../assests/imgs/Bottoms.png";
import bottoms from "../../../assests/imgs/Bottoms.png";
import elipseBottom from "../../../assests/imgs/elipseBottom.png";
import elipseLeft from "../../../assests/imgs/elipseLeft.png";
import elipseRight from "../../../assests/imgs/elipseRight.png";
import fundoinfo from "../../../assests/imgs/fundoinfo.png";
import secImg from "../../../assests/imgs/Gloria-Pires.png";
import exemploimg from "../../../assests/imgs/Gloria-Pires.png";
import floresRaras from "../../../assests/imgs/Gloria-Pires.png";
import iconfilm from "../../../assests/imgs/iconfilm.png";
import terstImg from "../../../assests/imgs/imagine-you-and-me.png";
import imagineYou from "../../../assests/imgs/imagine-you-and-me.png";
import iconclass from "../../../assests/imgs/incon-class.png";
import logomovie from "../../../assests/imgs/logomovie.svg";
import loveLies from "../../../assests/imgs/Love-Liers-Bleeding.jpg";
import seta from "../../../assests/imgs/setaLongaAcima.png";
import texthomepage from "../../../assests/imgs/texthomepage.png";
import fundohomepagevideo from "../../../assests/videos/happiestSeasonTrailerPTBR.mp4";
import ButtonBranco from "../../../Components/Buttons/ButtonBranco";
import ButtonGradient from "../../../Components/Buttons/ButtonGradient";
import MenuHome from "../../../Components/Menu";
import Therm from "../../../Components/Thermometer";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import FadedImageComponent, {
  Body,
  BodyImg,
  Box,
  BoxNoHover,
  ButtonDisplay,
  ButtonImg,
  DivButtons,
  DivideBody,
  DivInfo,
  DivLogo,
  DivSinopse,
  LightEllipse,
  OverlayImage3,
  TextBody,
  TittlePage,
  TittlePageName,
} from "./styled";

const Info = () => {
  const { userInfos } = useContext(GlobalStateContext);

  const navigate = useNavigate();

  const [isVideoEnded, setIsVideoEnded] = useState(false);

  // Função para tratar o evento de término do vídeo
  const handleVideoEnd = () => {
    setIsVideoEnded(true);
  };

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const mockProducts = [
    {
      id: 1,
      name: "Flores Raras",
      image: floresRaras,
    },
    {
      id: 2,
      name: "Imagine Me and You",
      image: imagineYou,
    },
    {
      id: 3,
      name: "Bottoms",
      image: bottoms,
    },
    {
      id: 1,
      name: "Love Lies Bleeding",
      image: loveLies,
    },
    {
      id: 2,
      name: "Série E",

      image: exemploimg,
    },
    {
      id: 3,
      name: "Série F",
      image: exemploimg,
    },
  ];

  const [products, setProducts] = useState(mockProducts);

  const productTemplate = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
          <img
            style={{ width: "96%", height: "214px", borderRadius: "20px" }}
            src={`${product.image}`}
            alt={product.name}
            className="w-6 shadow-2"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const video = document.getElementById("background-video");
    if (video) {
      video.addEventListener("ended", handleVideoEnd);
    }

    return () => {
      if (video) {
        video.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, []);

  return (
    <>
      <BodyImg>
        <FadedImageComponent />

        <div
          style={{
            position: "absolute",
            top: "10px",
            marginLeft: "35px",
          }}
        >
          <ButtonImg onClick={() => navigate("/")}>
            <img src={seta} alt="previousPage" />
          </ButtonImg>
        </div>

        <div
          style={{
            position: "absolute",
            top: "350px",
            marginLeft: "85px",
            width: "80%",
            zIndex: "3",
          }}
        >
          <DivLogo>
            <img src={logomovie} />
          </DivLogo>

          <DivButtons>
            <ButtonGradient
              text={"Assistir"}
              onClick={() => navigate("/movieScreen")}
            />
            <img src={iconfilm} />
            <Therm />
          </DivButtons>
          <DivInfo>
            <TextBody>1h42 min</TextBody>
            <img src={iconclass} />
            <TextBody>2020</TextBody>
            <TextBody>Drama</TextBody>
          </DivInfo>
          <DivSinopse>
            As namoradas Harper e Abby visitam a família de Harper para o jantar
            anual de Natal. No entanto, logo após chegar, Abby percebe que a
            moça tem mantido seu relacionamento em segredo de seus pais
            conservadores.
          </DivSinopse>
        </div>
      </BodyImg>
      <Body>
        <img
          src={elipseBottom}
          alt="bottom cover"
          style={{
            position: "absolute",
            bottom: "0",
            pointerEvents: "none",
            objectFit: "cover",
            bottom: "0",
            height: "auto",
            width: "100",
            top: "500",
          }}
        />
        <img
          src={elipseRight}
          alt="right cover"
          style={{
            position: "absolute",
            right: "0",
            bottom: "150",
            zIndex: "2",
            pointerEvents: "none",
            objectFit: "cover",
            bottom: "0",
            height: "auto",
            width: "100",
            top: "500",
          }}
        />
        <img
          src={elipseLeft}
          alt="left cover"
          style={{
            position: "absolute",
            left: "0",
            zIndex: "2",
            pointerEvents: "none",
            objectFit: "cover",
            bottom: "0",
            height: "auto",
            width: "100",
            top: "500",
          }}
        />
        <DivideBody style={{ gap: "40px" }}>
          <BoxNoHover
            style={{
              width: "45%",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "3%",
            }}
          ></BoxNoHover>
        </DivideBody>
        <DivideBody>
          <div className="card">
            <TextBody
              style={{ fontSize: 25, color: "white", marginLeft: "50px" }}
            >
              Títulos Relacionados
            </TextBody>

            <Carousel
              value={products}
              numVisible={4}
              numScroll={1}
              responsiveOptions={responsiveOptions}
              className="custom-carousel"
              circular
              data-pr-autohide
              itemTemplate={productTemplate}
            />
          </div>
        </DivideBody>
      </Body>
    </>
  );
};

export default Info;
