import styled from "styled-components";



export const Body = styled.div`
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
    background-size: cover;
    background-position: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

`



export const CardLogin = styled.div`
    height: 470px;
    width: 450px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
`

export const TittleCard = styled.text`
font-style: normal;
font-weight: 300;
font-size: 30px;
line-height: 29px;
letter-spacing: 0.2em;



`

export const InputLogin = styled.input`
    height: 35px;
    width: 90%;
    background: #D9D9D9;
    border: none;
    border-radius: 5px;
    padding-left: 10px;

    @font-face {
    font-family: 'Modern Sans', sans-serif;
    src: url(font) format("ttf");
}

    ::placeholder{
    font-weight: 100;
    font-size: 14px;
    line-height: 29px;
    letter-spacing: 0.2em;
    }


`

export const ButtonLogin = styled.button`
    width: 90%;
    height: 35px;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.35%, #FC9B54 100%);
    border: none;
    border-radius: 5px;
    margin-top: 30px;
    font-weight: 100;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.2em;
    cursor: pointer;

`

export const ForgetButton = styled.text`
    font-style: normal;
    font-weight: 300;
    line-height: 29px;
    font-size: 15px;
    letter-spacing: 0.2em;
    text-decoration: underline;
    
    cursor: pointer;

`

export const CheckBox = styled.input`
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border-radius: 2px;
    transform: rotate(45deg);
`
export const CheckBoxText = styled.text`
    font-style: normal;
    font-weight: 300;
    line-height: 29px;
    font-size: 15px;
    letter-spacing: 0.2em;

`