import axios from 'axios';

const prod = 'https://lezzes.grapenexus.com/api'
const local = 'http://127.0.0.1:3333'


const api = axios.create({
    baseURL: 'https://lezzes.grapenexus.com/api',
  });

  api.interceptors.request.use(async (config) => {
    const accessToken = localStorage.getItem('@token');
  
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  
    return config;
  });



export default api;